<template>
  <div id="checkProfile">
    <ControlNavbar/>
    <page-header v-if="pageTitle" :title="pageTitle"/>
    <br/>
    <div class="container" v-if="profileInfo">
      <div class="row d-flex justify-content-center">
        <div class="col">
          <profile-image
              :profileType="profileInfo.profile.typeLabel"
              :name="profileInfo.accountName"
          />
        </div>
      </div>
      <br/>
      <div
          v-for="(field, index) in profileInfo.profile.fields"
          :key="index"
          class="d-flex justify-content-center"
      >
        <div class="align-start mb-5" v-if="field.type == 'text'">
          <label :for="index"> {{ field.hint }}: </label><br/>
          <input
              :type="field.input"
              :id="index"
              :value="field.value"
              disabled
          />
        </div>
        <div class="align-center mb-5" v-else-if="field.type == 'header'">
          <h4 :style="`color: ${field.color}`">
            {{ field.label }}
          </h4>
        </div>
        <div class="align-start mb-5" v-else-if="field.type == 'dropdown'">
          <div v-for="option in field.options" :key="option.id">
            <div v-if="option.id == field.value">
              <label :for="index"> {{ field.hint }}: </label><br/>
              <input
                  :type="field.input"
                  :id="index"
                  :value="option.label"
                  disabled
              />
            </div>
          </div>
        </div>
        <div
            class="align-start mb-5"
            v-else-if="field.type === 'listdropdown'"
            style="width: 27%"
        >
          <label :for="field.id"> {{ field.label }}: </label><br/>
          <div :id="field.id">
            <div class="d-flex justify-content-between">
              <button
                  class="whish-button"
                  v-for="option in field.options"
                  :key="option.id"
                  @click="showListDrpDwn(option, field.options)"
              >
                {{ option.label }}
              </button>
            </div>

            <br/>
            <div v-for="option in field.options" :key="`${option.id}1`">
              <div v-if="field.value == option.id" :id="option.id">
                <div
                    v-for="f in field.fields[`${option.id}`]"
                    :key="`${f.id}`"
                    class="row mb-5"
                >
                  <div class="col-4">
                    <label :for="f.id"> {{ f.label }} </label>
                  </div>
                  <div class="col-8">
                    <div :id="f.id" v-if="f.clips">
                      <div
                          v-for="clip in f.clips"
                          :key="clip.id"
                          class="previewThumb"
                      >
                        <img
                            width="150px"
                            height="115px"
                            :src="getImage(clip.id, 'thumb')"
                            @click="largify(clip.id)"
                            :id="clip.id"
                        />
                        <a-tooltip>
                          <template slot="title">
                            {{ clip.status.text }}
                          </template>
                          <img
                              :src="clip.status.image"
                              style="
                              width: 25px;
                              position: relative;
                              top: 40px;
                              right: 43px;
                            "
                              alt=""
                          />
                        </a-tooltip>
                      </div>
                    </div>
                  </div>

                  <br/>
                </div>
              </div>
              <div v-else class="disp-none" :id="option.id">
                <div v-for="f in field.fields[`${option.id}`]" :key="`${f.id}`">
                  <label :for="f.id"> {{ f.label }} </label>
                  <div :id="f.id" v-if="f.clips">
                    <div
                        v-for="clip in f.clips"
                        :key="clip.id"
                        class="previewThumb"
                    >
                      <img
                          width="150px"
                          height="115px"
                          :src="getImage(clip.id, 'thumb')"
                          @click="largify(clip.id)"
                          :id="clip.id"
                      />
                      <a-tooltip>
                        <template slot="title">
                          {{ clip.status.text }}
                        </template>
                        <img
                            :src="clip.status.image"
                            style="
                            width: 25px;
                            position: relative;
                            top: 40px;
                            right: 43px;
                          "
                            alt=""
                        />
                      </a-tooltip>
                    </div>
                  </div>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="d-flex justify-content-between align-items-center"
            v-else-if="field.type == 'image'"
        >
          <label :for="field.id"> {{ field.label }} </label>
          <div
              :id="field.id"
              v-for="image in field.images"
              :key="image.id"
              class="previewThumb mr-2 ml-2"
          >
            <img
                width="150px"
                height="115px"
                :src="getImage(image.id, 'thumb')"
                @click="largify(image.id)"
                :id="image.id"
            />
          </div>
        </div>
        <!-- <div v-else>{{ field.hint }} {{ field.type }}</div> -->
      </div>
      <div class="row d-flex justify-content-center mt-5">
        <button class="whish-button mr-3" @click="editProfile">
          {{ profileInfo.button }}
        </button>
        <button class="whish-button ml-3" @click="logout">
          {{ `${locale.logout}` }}
        </button>
      </div>
      <br/><br/>
    </div>
    <a-modal v-model="largifyVisible" :footer="null">
      <div style="margin: 3%">
        <img ref="largifyimg" src="" width="100%" alt=""/>
      </div>
    </a-modal>
    <loading :loading="loading"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import "bootstrap";
import ControlNavbar from "@/components/ControlNavbar";
import {API_ENDPOINTS} from "../scripts/request";
import ProfileImage from "../components/ProfileImage.vue";
import PageHeader from "../components/PageHeader.vue";
import Loading from "../components/Loading.vue";
import firebase from "firebase";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "CheckProfile",
  components: {ControlNavbar, ProfileImage, PageHeader, Loading},
  data() {
    return {
      locale: null,

      pageTitle: null,
      loading: false,
      profileInfo: null,
      largifyVisible: false,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    editProfile() {
      this.$router.push({
        name: "Profile",
        params: {target: null},
      });
    },
    logout() {
      this.loading = true;
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            this.loading = false;
            console.log(this.checkApiResponse(result));
            if (this.checkApiRes(result, {sessionId: this.$session.get("sessionId"),}, "account/session/deactivate", "post")) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");
                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    largify(id) {
      this.loading = true;
      this.getImageAxios()
          .get(`${this.$session.get("baseURI")}image/${id}/full`)
          .then((response) => {
            this.loading = false;
            let self = this;
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                  // convert image file to base64 string
                  self.largifyVisible = true;
                  setTimeout(function () {
                    self.$refs.largifyimg.src = reader.result;
                  }, 500);
                },
                false
            );
            reader.readAsDataURL(response.data);
          });
    },
    getImageAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
        responseType: "blob",
      });
    },
    getImage(id) {
      this.getImageAxios()
          .get(`${this.$session.get("baseURI")}image/${id}/thumb`)
          .then((response) => {
            // console.log(Buffer.from(response.data, "base64"));
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                function () {
                  // convert image file to base64 string
                  document.getElementById(`${id}`).src = reader.result;
                },
                false
            );
            reader.readAsDataURL(response.data);
          });
    },
    showListDrpDwn(option, options) {
      options.forEach((o) => {
        document.getElementById(`${o.id}`).style.display = "none";
      });

      document.getElementById(`${option.id}`).style.display = "inherit";
    },
    checkApiResponse(result) {
      try {
        if (result.headers.sessioncounter) {
          this.$session.set("sessionCounter", result.headers.sessioncounter);
        }
        if (!result.data.status) {
          // error
          this.$message.error(result.data.dialog.message);
          console.error(`error in (${result.config.url})`, result);
          if (result.data.code === "auth.session_expired") {
            // log user out
            this.logout();
          }
        } else {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    getProfileData() {
      this.loading = true;
      this.getAxios()
          .get(`${API_ENDPOINTS.checkProfileData}`)
          .then((result) => {
            this.loading = false;
            if (this.checkApiRes(result, null, API_ENDPOINTS.checkProfileData, "get")) { //the endpoint doesnt matter because purchase confirm doesnt apply on this one
              let profileData = result.data.data;
              this.pageTitle = profileData.pageTitle;
              this.profileInfo = profileData;
              console.log("check profile", profileData);

              // saving servicesVersion & profile image
              this.$session.set("profileImage", profileData.profileImage);
              this.$session.set(
                  "servicesversion",
                  this.$session.get("profile_data").headers.servicesversion
              );
            }
          });
    },
  },
  mounted: function () {
    this.checkLocal();
    this.getProfileData();
  },
};
</script>

<style lang="scss" scoped>
.previewThumb {
  img {
    border-radius: 15px;
  }
}

.disp-none {
  display: none;
}

#checkProfile {
  label {
    color: grey;
  }

  input {
    width: 300px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid grey;
  }
}

.align-start {
  text-align: start;
}

.align-center {
  text-align: center;
}

.whish-button {
  background-color: #e62946; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 15px;
}
</style>
