<template>
  <div class="profileImage">
    <div class="row d-flex justify-content-center align-items-center">
      <img
          ref="backendProfilePic"
          class="profile-pic mb-2"
          src="../assets/ProfilePicturePlaceholder.png"
          alt="WHISH LOGO"
      />
      <p class="align-start ml-2">
        <strong>{{ name }}</strong> <br/><span>{{ profileType }}</span>
      </p>
      <br/>
      <p></p>
    </div>
    <div class="row d-flex justify-content-center">
      <button @click="openPicModal">{{ locale.change }}</button>
    </div>
    <a-modal v-model="visible" :footer="null">
      <div class="p-2">
        <div class="row mb-2">
          <img id="previewImg" ref="previewImage" alt=""/>
        </div>
        <input type="file" @change="previewFile" accept="image/*"/>
        <div class="row p-5 d-flex justify-content-around" v-if="showSave">
          <button @click="closePicModal">{{ locale.cancel }}</button>
          <button @click="saveImage">{{ locale.save }}</button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

// @ is an alias to /src

export default {
  name: "ProfileImage",
  components: {},
  props: {profileType: String, name: String},
  data() {
    return {visible: false, showSave: false, locale: null};
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    openPicModal() {
      this.visible = true;
    },
    closePicModal() {
      this.visible = false;
    },
    previewFile(e) {
      console.log(this.getBase64(e.target.files[0]));
    },
    getBase64(file) {
      let self = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        self.$refs.previewImage.src = reader.result;
        self.showSave = true;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    saveImage() {
      let image = this.$refs.previewImage.src.split("base64,")[1];
      console.log(image);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/profilepic`, {
            id: "profile",
            data: image,
          })
          .then((result) => {
            this.closePicModal();
            if (this.checkApiRes(result, {id: "profile", data: image,}, "account/profilepic", "post")) {
              console.log(result);
            }
          });
    },
    getImage(id) {
      let ax = axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
        responseType: "blob",
      });
      ax.get(`${this.$session.get("baseURI")}image/${id}/full`).then(
          (response) => {
            let self = this;
            // console.log(Buffer.from(response.data, "base64"));
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                function () {
                  // convert image file to base64 string
                  // console.log(reader.result);
                  if (self.$refs.backendProfilePic)
                    self.$refs.backendProfilePic.src = reader.result;
                },
                false
            );
            reader.readAsDataURL(response.data);
          }
      );
    },
  },
  created() {
    this.checkLocal();
    this.getImage(this.$session.get("profileImage"));
  },
};
</script>

<style lang="scss" scoped>
#previewImg {
  width: -webkit-fill-available;
}

img.profile-pic {
  border-radius: 50%;
  width: 140px;
  height: 140px;
}

button {
  background-color: #e62946; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.align-start {
  text-align: start;
}
</style>
